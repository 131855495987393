import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service'
import { ToastService } from 'src/app/services/toast/toast.service';

import { ICategory, Category } from 'src/app/models/category.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public categories: Array<Category> = [];
  public subCategories: Array<Category> = [];
  public currentCategory: Category | null;
  public loadingCategories: boolean = true;
  public loadingSubcategories: boolean = true;
  public currentCategoryLoading: boolean = false;
  public currentCategoryChange: Subject<Category> = new Subject<Category>();



  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public loaderService: LoaderService,
    private toastService: ToastService,
  ) { 
    this.getData();
    this.currentCategory = null;
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION getCategories                           */
  /* -------------------------------------------------------------------------- */

  async getCategories() {
    this.loadingCategories = true;
    this.categories = await new Promise((resolve, reject) => {
      this.http.get(`${environment.urlApi}/categories`)
      .subscribe((response: any) => {
        let categories = response.data.categories
        .map((category: ICategory) => new Category(category));

        resolve(categories);
        
      })
    });
    this.loadingCategories = false;
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION geData                               */
  /* -------------------------------------------------------------------------- */

  async getData() {
    this.getCategories();    
    this.getSubcategories();      
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION getCategoryData                      */
  /* -------------------------------------------------------------------------- */

  async getCategoryData(idCategory: number): Promise<Category | null> {
    let category = null;

    this.currentCategoryLoading = true;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.get(
        `${environment.urlApi}/categories/${idCategory}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      ). subscribe((response: any) => {
        resolve(response);
      })
    });

    if(response.statusCode == 200){
      this.currentCategory = new Category(response.data.category);
      this.currentCategoryChange?.next(new Category(response.data.category));
      category = new Category(response.data.category);
    }
    
    this.currentCategoryLoading = false;

    return category;
  }

  /* -------------------------------------------------------------------------- */
  /*                          FUNCTION getSubcategories                         */
  /* -------------------------------------------------------------------------- */

  async getSubcategories() {
    this.loadingSubcategories = true;

    let response = await new Promise<any>((resolve, reject) => {
      this.http.get(
        `${environment.urlApi}/categories?subcategories=true`
      ).subscribe((response: any) => {
        resolve(response);
      })
    });

    if(response.statusCode == 200){
      this.subCategories = response.data.subcategories
      .map((category: Category) => new Category(category));
    }

    console.log(this.subCategories);
    

    this.loadingSubcategories = false;
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION createCategory                          */
  /* -------------------------------------------------------------------------- */
 
  async createCategory(category: ICategory) {
    this.loaderService.loading = true;
    this.loaderService.message = 'Agregando nueva categoria'

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.post(
        `${environment.urlApi}/categories`,
        JSON.stringify(category),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ). subscribe((response: any) => {
        resolve(response);
      })
    });

    if(response.statusCode == 201){
      this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
      this.loaderService.loading = false;
      this.loaderService.message = ''

      this.getCategories();
    } else {
      this.toastService.show(response.message, {classname: `bg-danger text-white rounded-lg shadow`});
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION getCategory                           */
  /* -------------------------------------------------------------------------- */

  async getCategory(idCategory: number, force: boolean = false): Promise<Category | null>{

    let category = null;
    
    if(this.currentCategory){
      if(!force) {
        if(this.currentCategory.idCategory == idCategory){
          category = this.currentCategory;
          return category;
        }
      }
    } else {
      if(this.currentCategoryLoading){
        category = this.currentCategory;
        return category;
      }
    }

    category = await this.getCategoryData(idCategory);

    return category;
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION deleteCategory                          */
  /* -------------------------------------------------------------------------- */

  async deleteCategory(category: Category){

    this.loaderService.loading = true;
    this.loaderService.message = `Borrando ${category.name}`;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.delete(
        `${environment.urlApi}/categories/${category.idCategory}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response: any) => {
        this.getData();
        resolve(response);
      })
    });

    this.loaderService.loading = false;
    this.loaderService.message = ''

    if(response.statusCode === 200){
      this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
    }

  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION updateCategory                          */
  /* -------------------------------------------------------------------------- */

  async updateCategory(category: ICategory){

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.put(
        `${environment.urlApi}/categories/${this.currentCategory?.idCategory}`,
        JSON.stringify(category),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode === 200) {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );

      this.getCategory(this.currentCategory?.idCategory!, true);
      this.getCategories();

    } else {
      this.toastService.show(response.message, {classname: `bg-danger text-white rounded-lg shadow`});
    }

  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION updateCategory                          */
  /* -------------------------------------------------------------------------- */

  async updateCategoryStatus(category: ICategory){

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.put(
        `${environment.urlApi}/categories/${this.currentCategory?.idCategory}`,
        JSON.stringify(category),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode === 200) {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );

      this.getCategory(this.currentCategory?.idCategory!, true);

    } else {
      this.toastService.show(response.message, {classname: `bg-danger text-white rounded-lg shadow`});
    }

  }
}


