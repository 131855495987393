import { Injectable } from '@angular/core';
import { 
  Auth, 
  signInWithEmailAndPassword,
  signOut,
  user,
  User
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';

import { EcmUser } from 'src/app/models/ecm-user.model';

import { LoaderService } from 'src/app/services/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Get if user is logged in or not
   */
  public loggedIn: boolean = false;

  /**
   * Firebase user
   */
  public currentFirebaseUser?: User;

  /**
   * Data of user from database
   */
  public currentUser?: EcmUser;

  constructor(
    public auth: Auth,
    private http: HttpClient,
    private loaderService: LoaderService,
    private router: Router,
  ) {

    user(this.auth).subscribe((_user) => {
      // console.log(_user);

      if(_user) {
        this.loggedIn = true;
        this.currentFirebaseUser = _user;
        this.getUser();
      } else {
        this.loggedIn = false;
      }
    });
  }

  async signOut() {
    this.loaderService.loading = true;
    this.loaderService.message = 'Cerrando sesión';

    await signOut(
      this.auth
    );

    this.currentUser = undefined;

    this.loaderService.loading = false;
    this.loaderService.message = '';

    sessionStorage.removeItem('user');
    this.loggedIn = false;
    this.router.navigate(['signin']);
  }

  async getUser(){

    this.loaderService.loading = true;
    this.loaderService.message = 'Cargando datos de usuario';

    let token = await this.currentFirebaseUser?.getIdToken();

    this.http.get(
      `${environment.urlApi}/account`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    ).subscribe((data: any) => {
      console.log(data);
      
      this.currentUser = data.data.user;
      // console.log(this.currentUser);

      this.loaderService.loading = false;
      this.loaderService.message = '';      
    });
  }

  public getDataUser(token: string): Promise<any>{
    return new Promise((resolve, reject) => {
      this.http.get(
        `${environment.urlApi}/account`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((data) => {
        console.log(data);
        
        resolve(data);
      })
    });
  }
}
