<div class="container-fluid pt-3">
    <div class="row">
        <div class="col-12">

            <ul ngbNav [activeId]="activeRoute" class="nav-pills">
                <li [ngbNavItem]="link.fragment" *ngFor="let link of links">
                  <a ngbNavLink [routerLink]="[link.fragment]">{{ link.title }}</a>
                </li>
            </ul>
            
        </div>

        <div class="col-12 position-relative">
            <router-outlet></router-outlet>

            <!-- <div class="position-absolute w-100 bg-danger" style="top: 0px; left: 0px">
                
                <div class="d-flex justify-content-center w-100">
                    <div class="loader"></div>
                </div>
                
            </div> -->

        </div>
    </div>
</div>