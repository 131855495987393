export interface IBuyButton {
   idBuyButton?: string;
   idStock?: string;
   description: string;
   color?: string;
   url: string;
   status: boolean;
   createdAt?: string;
   updatedAt?: string;
}

export class BuyButton {
   public idBuyButton?: string;
   public idStock?: string;
   public description: string;
   public color?: string;
   public url: string;
   public status: boolean;
   public createdAt?: Date;
   public updatedAt?: Date;

   constructor(data: IBuyButton) {
      this.idBuyButton = data.idBuyButton;
      this.idStock = data.idStock;
      this.description = data.description;
      this.color = data.color;
      this.url = data.url;
      this.status = data.status;
      this.createdAt = data.createdAt
         ? new Date(data.createdAt)
         : undefined;
      this.updatedAt = data.updatedAt
         ? new Date(data.updatedAt)
         : undefined;
   }
}