import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { Product } from 'src/app/models/product.model';
import { IStock, Stock } from 'src/app/models/stock.model';

import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductService } from 'src/app/services/product/product.service'
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  public currentStock?: Stock;
  public currentStockChange: Subject<Stock> = new Subject<Stock>();
  public currentStockLoading: boolean = true;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public loaderService: LoaderService,
    private productService: ProductService,
    private toastService: ToastService,
  ) { 
    // this.currentStock
  }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION create                              */
  /* -------------------------------------------------------------------------- */

  async create(
    product: Product, stock: IStock
  ): Promise<boolean> {
    let flag = false;

    this.loaderService.loading = true;
    this.loaderService.message = 'Creando stock';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.post(
        `${environment.urlApi}/products/${product.idProduct}/stocks`,
        JSON.stringify(stock),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 201) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    this.loaderService.loading = false;
    this.loaderService.message = '';
  
    return flag;
  }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION delete                              */
  /* -------------------------------------------------------------------------- */

  async delete(idProduct: string, stock: Stock): Promise<boolean> {
    let flag = false;

    this.loaderService.loading = true;
    this.loaderService.message = 'Borrando stock';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.delete(
        `${environment.urlApi}/products/${idProduct}/stocks/${stock.idStock}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 200) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    this.loaderService.loading = false;
    this.loaderService.message = '';

    return flag;
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION getStock                             */
  /* -------------------------------------------------------------------------- */

  async getStock(
    idProduct: string, 
    idStock: string
  ): Promise<Stock | null> {
    let stock = null;

    this.currentStockLoading = true;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    // console.log(this.productService.currentProduct);

    let response = await new Promise<any>((resolve, reject) => {
      this.http.get(
        `${environment.urlApi}/products/${idProduct}/stocks/${idStock}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response: any) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 200) {
      this.currentStock = new Stock(response.data.stock);
      this.currentStockChange?.next(new Stock(response.data.stock));
      stock = new Stock(response.data.stock);
    }

    console.log(stock);

    this.currentStockLoading = false;

    return stock;
  }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION update                              */
  /* -------------------------------------------------------------------------- */

  async update(
    idProduct: string, stock: IStock
  ): Promise<boolean> {
    let flag = false;

    this.loaderService.loading = true;
    this.loaderService.message = 'Actualizando stock';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.put(
        `${environment.urlApi}/products/${idProduct}/stocks/${this.currentStock?.idStock!}`,
        JSON.stringify(stock),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 200) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    this.getStock(idProduct, this.currentStock?.idStock!);

    this.loaderService.loading = false;
    this.loaderService.message = '';
  
    return flag;
  }
}
