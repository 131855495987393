import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { IFeature } from 'src/app/models/feature.model';
import { IProduct, Product } from 'src/app/models/product.model';

import { AuthService } from 'src/app/services/auth/auth.service';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { TagService } from 'src/app/services/tag/tag.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public products: Array<Product> = [];
  public loading: boolean = true;
  public currentProduct: Product | null;
  public currentProductChange: Subject<Product> = new Subject<Product>();
  public currentProductLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private featureService: FeatureService,
    private http: HttpClient,
    public loaderService: LoaderService,
    private tagService: TagService,
    private toastService: ToastService,
  ) { 
    this.getProducts();
    this.currentProduct = null;
    // this.currentProductChange = null;
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION addFeature                            */
  /* -------------------------------------------------------------------------- */

  async addFeature(feature: IFeature){
    let flag = await this.featureService.create(
      this.currentProduct!, feature
    );

    if(flag) {
      // console.log(flag);
      
      this.getData(this.currentProduct?.idProduct!);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION addTag                              */
  /* -------------------------------------------------------------------------- */

  async addTag(tag: string){
    let flag = await this.tagService.addTag(
      this.currentProduct!, tag
    );

    if(flag) {
      // console.log(flag);
      
      this.getData(this.currentProduct?.idProduct!);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION createProduct                           */
  /* -------------------------------------------------------------------------- */

  async createProduct(product: IProduct) {
    this.loaderService.loading = true;
    this.loaderService.message = 'Agregando nuevo producto'

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.post(
        `${environment.urlApi}/products`,
        JSON.stringify(product),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ). subscribe((response: any) => {
        resolve(response);
      })
    });

    if(response.statusCode == 201){
      this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
      this.loaderService.loading = false;
      this.loaderService.message = ''

      this.getProducts();
    } else {
      this.toastService.show(response.message, {classname: `bg-danger text-white rounded-lg shadow`});
    }
  }

  async deleteFeature(idFeature: string) {
    let flag = await this.featureService.delete(
      this.currentProduct!,
      idFeature
    );

    if(flag) {
      // console.log(flag);
      
      this.getData(this.currentProduct?.idProduct!);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION deleteProduct                           */
  /* -------------------------------------------------------------------------- */

  async deleteProduct(product: Product){

    this.loaderService.loading = true;
    this.loaderService.message = `Borrando ${product.name}`;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.delete(
        `${environment.urlApi}/products/${product.idProduct}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response: any) => {
        this.getProducts();
        resolve(response);
      })
    });

    this.loaderService.loading = false;
    this.loaderService.message = ''

    if(response.statusCode === 200){
      this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
    }

  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION deleteTag                             */
  /* -------------------------------------------------------------------------- */

  async deleteTag(tag: string){
    let flag = await this.tagService.deleteTag(
      this.currentProduct!, tag
    );

    if(flag) {
      // console.log(flag);
      
      this.getData(this.currentProduct?.idProduct!);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION getData                              */
  /* -------------------------------------------------------------------------- */

  async getData(idProduct: string): Promise<Product | null> {
    let product = null;

    this.currentProductLoading = true;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.get(
        `${environment.urlApi}/products/${idProduct}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      ). subscribe((response: any) => {
        resolve(response);
      })
    });

    if(response.statusCode == 200){
      this.currentProduct = new Product(response.data.product);
      this.currentProductChange?.next(new Product(response.data.product));
      product = new Product(response.data.product);
    }

    console.log(product);
    
    this.currentProductLoading = false;

    return product;
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION getProduct                            */
  /* -------------------------------------------------------------------------- */

  async getProduct(idProduct: string, force: boolean = false): Promise<Product | null>{

    let product = null;
    
    if(this.currentProduct){
      if(!force) {
        if(this.currentProduct.idProduct == idProduct){
          product = this.currentProduct;
          return product;
        }
      }
    } else {
      if(this.currentProductLoading){
        product = this.currentProduct;
        return product;
      }
    }

    product = await this.getData(idProduct);

    return product;
  }

  /* -------------------------------------------------------------------------- */
  /*                            FUNCTION getProducts                            */
  /* -------------------------------------------------------------------------- */

  async getProducts(){
    this.loading = true;
    this.products = await new Promise((resolve, reject) => {
      this.http.get(`${environment.urlApi}/products`)
      .subscribe((response: any) => {
        let products = response.data.products
        .map((product: IProduct) => new Product(product));
        resolve(products);
      })
    });

    this.loading = false;
    
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION updateProduct                           */
  /* -------------------------------------------------------------------------- */

  async updateProduct(product: IProduct){

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.put(
        `${environment.urlApi}/products/${this.currentProduct?.idProduct}`,
        JSON.stringify(product),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode === 200) {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );

      this.getProduct(this.currentProduct?.idProduct!, true);

    } else {
      this.toastService.show(response.message, {classname: `bg-danger text-white rounded-lg shadow`});
    }

  }
}
