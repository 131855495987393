export interface ICategory {
    idCategory: number;
    superCategory?: number;
    name: string;
    idImage?: number;
    status: boolean;
    recordDate: string;
    lastUpdate: string;
    subcategories: Array<Category>;
}

export class Category {
    public idCategory: number;
    public superCategory?: number;
    public name: string;
    public idImage?: number;
    public status: boolean;
    public recordDate: string;
    public lastUpdate: string;
    public subcategories: Array<ICategory>;

    constructor(
        options: ICategory
    ) {
        this.idCategory = options.idCategory;
        this.superCategory = options.superCategory;
        this.name = options.name;
        this.idImage = options.idImage;
        this.status = options.status;
        this.recordDate = options.recordDate;
        this.lastUpdate = options.lastUpdate;
        this.subcategories = options.subcategories 
            ? options.subcategories
                .map((category: ICategory) => new Category(category))
            : [];
    }
}
