import { Breadcrumb } from 'src/app/models/breadcrumb.model';

export const BREADCRUMBS: Array<Breadcrumb> = [
    {
        path: 'brands',
        name: 'Marcas',
        show: true,
    },
    {
        path: 'categories',
        name: 'Categorias',
        show: true,
    },
    {
        path: 'dashboard',
        name: 'Inicio',
        show: true,
    },
    {
        path: 'features',
        name: 'Características',
        show: true,
    },
    {
        path: 'general',
        name: 'General',
        show: true,
    },
    {
        path: 'images',
        name: 'Imágenes',
        show: true,
    },
    {
        path: 'items',
        name: 'Items',
        show: true,
    },
    {
        path: 'logs',
        name: 'Bitácora',
        show: true,
    },
    {
        path: 'media',
        name: 'Media',
        show: true,
    },
    {
        path: 'products',
        name: 'Productos',
        show: true,
    },
    {
        path: 'security',
        name: 'Seguridad',
        show: true,
    },
    {
        path: 'settings',
        name: 'Configuración',
        show: true,
    },
    {
        path: 'status',
        name: 'Estatus',
        show: true,
    },
    {
        path: 'stocks',
        name: 'Inventario',
        show: true,
    },
    {
        path: 'store',
        name: 'store',
        show: false,
    },
    {
        path: 'tags',
        name: 'Palabras clave',
        show: true,
    },
]