import { Brand, IBrand } from 'src/app/models/brand.model';
import { Category, ICategory } from './category.model';
import { Feature, IFeature } from 'src/app/models/feature.model';
import { IStock, Stock } from 'src/app/models/stock.model';
import { ITag, Tag } from './tag.model';

export interface IProduct {
    idProduct: string;
    sku: string;
    name: string;
    description: string;
    idBrand: number;
    idCategory: number;
    only: boolean;
    status: boolean;
    recordDate: string;
    lastUpdate: string;
    brand: IBrand;
    category: ICategory;
    features: Array<IFeature>;
    stocks: Array<IStock>;
    tags: Array<ITag>;
}

export class Product {

    public idProduct: string;
    public sku: string;
    public name: string;
    public description: string;
    public idBrand: number;
    public idCategory: number;
    public only: boolean;
    public status: boolean;
    public recordDate: string;
    public lastUpdate: string;
    public brand: Brand;
    public category: Category;
    public features: Array<Feature>;
    public stocks: Array<Stock>;
    public tags: Array<Tag>

    constructor(
        options: IProduct
    ) {
        this.idProduct = options.idProduct;
        this.sku = options.sku;
        this.name = options.name;
        this.description = options.description;
        this.idBrand = options.idBrand;
        this.idCategory = options.idCategory;
        this.only = options.only;
        this.status = options.status;
        this.recordDate = options.recordDate;
        this.lastUpdate = options.lastUpdate;

        this.brand = new Brand(options.brand);
        this.category = new Category(options.category);
        this.features = options.features
            ? options.features
                .map(feature => new Feature(feature))
            : [];
        this.stocks = options.stocks
            ? options.stocks
                .map((stock) => new Stock(stock))
            : [];
        this.tags = options.tags
            ? options.tags
                .map((tag) => new Tag(tag))
            : [];
    }

}
