import { Injectable } from '@angular/core';
import { 
  ActivatedRouteSnapshot, 
  CanActivate, 
  RouterStateSnapshot,
  Router,
  UrlTree 
} from '@angular/router';
import { Observable } from 'rxjs';
import { 
  Auth,
  user
} from '@angular/fire/auth';

import { LoaderService } from 'src/app/services/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedInGuard implements CanActivate {


  constructor(
    public auth: Auth,
    private loaderService: LoaderService,
    private router: Router
  ){ }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.loaderService.loading = true;
      this.loaderService.message = 'Cargando sesión';

      return new Promise((resolve, reject) => {
        user(this.auth).subscribe((_user) => {

          this.loaderService.loading = false;
          this.loaderService.message = '';

          if (_user){
            resolve(true);
          } else {
            this.router.navigate(['/signin']);
            resolve(false);
          }
        });
      });
  }
  
}
