import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Category } from 'src/app/models/category.model';

import { CategoryService } from 'src/app/services/category/category.service';

@Component({
  selector: 'app-category-layout',
  templateUrl: './category-layout.component.html',
  styleUrls: ['./category-layout.component.scss']
})
export class CategoryLayoutComponent implements OnInit {

  links = [
    { title: 'General', fragment: 'general' },
  ];

  public category: Category | null = null;

  public activeRoute: string = 'general'

  constructor(
    public activatedRoute: ActivatedRoute,
    public categoryService: CategoryService,
    public router: Router
  ) {
    this.router.events.subscribe((data) => {
      let paths = this.router.url.split('/');
      this.activeRoute = paths[paths.length - 1];
    });
  }

  ngOnInit(): void {
    this.getDetail(
      parseInt(this.activatedRoute.snapshot.paramMap.get('idCategory')!)
    );
  }

  async getDetail(idCategory: number) {
    this.category = await this.categoryService.getCategory(idCategory);
  }

}
