import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  /**
   * If Loader is loading
   */
  public loading:boolean = false;

  /**
   * Loader's message to show up on Loader
   */
  public message:string = "Cargando";

  constructor() { }
}
