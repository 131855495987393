import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { LoaderService } from 'src/app/services/loader/loader.service';
import { SlideMenuService } from 'src/app/services/slide-menu/slide-menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false
})
export class AppComponent {
  
  constructor(
    public loaderService: LoaderService,
    public slideMenuService: SlideMenuService,
  ) { }
}
