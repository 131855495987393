import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { Feature, IFeature } from 'src/app/models/feature.model';
import { Product } from 'src/app/models/product.model';

import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public loaderService: LoaderService,
    private toastService: ToastService,
  ) { }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION create                              */
  /* -------------------------------------------------------------------------- */

  async create(product: Product, feature: IFeature):Promise<boolean> {
    let flag = false;

    this.loaderService.loading = true;
    this.loaderService.message = 'Creando característica';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.post(
        `${environment.urlApi}/products/${product.idProduct}/features`,
        JSON.stringify(feature),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 201) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    this.loaderService.loading = false;
    this.loaderService.message = '';

    return flag;
  }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION delete                              */
  /* -------------------------------------------------------------------------- */

  async delete(product: Product, idFeature: string):Promise<boolean> {
    let flag = false;

    this.loaderService.loading = true;
    this.loaderService.message = 'Borrando característica';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.delete(
        `${environment.urlApi}/products/${product.idProduct}/features/${idFeature}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 200) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    this.loaderService.loading = false;
    this.loaderService.message = '';

    return flag;
  }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION update                              */
  /* -------------------------------------------------------------------------- */

  async update(product: Product, feature: IFeature):Promise<boolean> {
    let flag = false;

    this.loaderService.loading = true;
    this.loaderService.message = 'Actualizando característica';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.put(
        `${environment.urlApi}/products/${product.idProduct}/features/${feature.idFeature}`,
        JSON.stringify(feature),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 200) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );

    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    this.loaderService.loading = false;
    this.loaderService.message = '';

    return flag;
  }

  /* -------------------------------------------------------------------------- */
  /*                            FUNCTION updateStatus                           */
  /* -------------------------------------------------------------------------- */

  async updateStatus(
    product: Product, 
    feature: Feature
  ):Promise<boolean> {
    let flag = false;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.put(
        `${environment.urlApi}/products/${product.idProduct}/features/${feature.idFeature}`,
        JSON.stringify({
          erasable: feature.erasable
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 200) {
      flag = true;
      
      response.data.updates.forEach((update: string) => {
        this.toastService.show(
          update, 
          {
            classname: `bg-success text-white rounded-lg shadow`
          }
        );
      });

    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    return flag;
  }
}
