<div 
    style="top: 0px; z-index: 1024;"
    class="position-absolute w-100 h-100">

    <div class="container-fluid h-100">
        <div class="row h-100">

            <div class="col-6 col-md-3 col-lg-3 col-xl-2 bg-primary p-0 d-flex align-items-between h-100">
                
                <div class="container-fluid">
                    <div class="row h-100">

                        <div class="col-12 p-0">
                            <app-side-bar></app-side-bar>
                        </div>

                        <div class="col-12 mt-auto mb-4">
                            <a
                                class="btn d-flex align-items-center justify-content-between text-white mb-3">
                                
                                <span class="d-flex align-items-center">
                                    {{ authService.currentUser?.name }} {{ authService.currentUser?.lastName }}
                                </span>
                            </a>
                            <a 
                                (click)="slideMenuService.showed = false; authService.signOut()"
                                class="btn d-flex align-items-center justify-content-between text-white">
                                <span class="d-flex align-items-center">
                                    <i 
                                        class="material-icons me-2">
                                        logout
                                    </i>
                                    Cerrar sesión
                                </span>
                                <!-- <i class="material-icons">chevron_right</i> -->
                            </a>
                        </div>

                    </div>
                </div>
                
            </div>

            <a 
                (click)="slideMenuService.showed = false"
                class="col-6 col-md-9 col-lg-9 col-xl-10">
                
            </a>

        </div>
    </div>

</div>