// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'ecm-master',
    appId: '1:805209402199:web:2ff6aafc24567bfce06c12',
    storageBucket: 'ecm-master.appspot.com',
    apiKey: 'AIzaSyB8yaJBUrjlVDHsRuyUEce8BdGBa_fIHwM',
    authDomain: 'ecm-master.firebaseapp.com',
    messagingSenderId: '805209402199',
    measurementId: 'G-3LDGQETRM5',
  },
  production: false,
  urlApi: 'https://us-central1-ecm-master.cloudfunctions.net/console/api',
  // urlApi: 'http://localhost:5001/ecm-master/us-central1/console/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
