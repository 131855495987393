import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';
import { SlideMenuService } from 'src/app/services/slide-menu/slide-menu.service';

@Component({
  selector: 'app-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrls: ['./slide-menu.component.scss']
})
export class SlideMenuComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public slideMenuService: SlideMenuService
  ) { }

  ngOnInit(): void {
  }

}
