import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-session-layout',
  templateUrl: './session-layout.component.html',
  styleUrls: ['./session-layout.component.scss'],
})
export class SessionLayoutComponent implements OnInit {

  public outerHeight: number = document.documentElement.clientHeight;

  constructor() { }

  ngOnInit(): void {
  }

}
