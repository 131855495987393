import { IBuyButton, BuyButton } from "./buy-button.model";
import { IFeature, Feature } from "./feature.model";
import { IImage, Image } from "./image.model";
import { IItem, Item } from "./item.model";

export interface IStock {
    idStock: string;
    price: number;
    shortDescription: string;
    includeTax: boolean;
    status: boolean;
    features: Array<IFeature>;
    images: Array<IImage>;
    items: Array<IItem>;
    buyButton?: IBuyButton;
}

export class Stock {
    public idStock;
    public price;
    public shortDescription;
    public includeTax;
    public status;
    public features: Array<Feature>;
    public images: Array<Image>;
    public items: Array<Item>;
    public buyButton?: BuyButton;

    constructor(
        options: IStock
    ) {
        this.idStock = options.idStock;
        this.price = options.price;
        this.shortDescription = options.shortDescription;
        this.includeTax = options.includeTax;
        this.status = options.status;
        this.features = options.features 
            ? options.features
                .map((feature) => new Feature(feature))
            : [];
        this.images = options.images 
            ? options.images
                .map((image) => new Image(image))
            : [];
        this.items = options.items 
            ? options.items
                .map((item) => new Item(item))
            : [];
        this.buyButton = options.buyButton != null
            ? new BuyButton(options.buyButton)
            : undefined;
    }
}
