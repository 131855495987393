import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

import { environment } from 'src/environments/environment';

import { IBrand, Brand } from 'src/app/models/brand.model';

import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service'
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  public brands: Array<Brand> = [];
  public loading: boolean = true;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public loaderService: LoaderService,
    private toastService: ToastService,
  ) { 
    this.getBrands();
  }

  async createBrand(brand: string){
    this.loaderService.loading = true;
    this.loaderService.message = 'Agregando nueva marca'

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    await new Promise((resolve, reject) => {
      this.http.post(
        `${environment.urlApi}/brands`,
        JSON.stringify({
          name: brand,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      .subscribe((response: any) => {
        console.log(response);
        
        this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
        this.loaderService.loading = false;
        this.loaderService.message = ''
        this.getBrands();
        resolve(true);
      });
    });

    // await this.getBrands();
  }

  async delete(brand: Brand){

    this.loaderService.loading = true;
    this.loaderService.message = `Borrando marca ${brand.name}`;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    await new Promise((resolve, reject) => {
      this.http.delete(
        `${environment.urlApi}/brands/${brand.idBrand}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      .subscribe((response: any) => {
        console.log(response);
        
        this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
        this.loaderService.loading = false;
        this.loaderService.message = ''
        this.getBrands();
        resolve(true);
      });
    });

  }

  async getBrands() {
    this.loading = true;
    await new Promise((resolve, reject) => {
      this.http.get(`${environment.urlApi}/brands`)
      .subscribe((response: any) => {

        // console.log(response);
        
        this.brands = response.data.brands
          .map((brand: IBrand) => new Brand(
            brand
          ));

        // console.log(this.brands);

        this.loading = false;
        
      })
    })
  }

  async update(brand: Brand) {

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    this.http.put(
      `${environment.urlApi}/brands/${brand.idBrand}`,
      JSON.stringify({
        name: brand.name,
        status: brand.status
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    ).subscribe((response: any) => {
      console.log(response);
      
      this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
      this.getBrands();
    });
  }

  async updateStatus(brand: Brand) {

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    this.http.put(
      `${environment.urlApi}/brands/${brand.idBrand}`,
      JSON.stringify({
        status: brand.status
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    ).subscribe((response: any) => {
      if(response.statusCode == 200){
        this.toastService.show('Estatus cambiado', {classname: `bg-success text-white rounded-lg shadow`});
      }
    });
  }
}
