import { Injectable } from '@angular/core';
import { 
  ActivatedRouteSnapshot, 
  CanActivate, 
  Router,
  RouterStateSnapshot, 
  UrlTree 
} from '@angular/router';
import { Observable } from 'rxjs';
import { 
  Auth,
  user
} from '@angular/fire/auth';

import { EcmUser } from 'src/app/models/ecm-user.model';

import { LoaderService } from 'src/app/services/loader/loader.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {

  constructor(
    public auth: Auth,
    private authService: AuthService,
    private loaderService: LoaderService,
    private router: Router
  ){ }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      this.loaderService.loading = true;
      this.loaderService.message = 'Cargando datos';

      return new Promise((resolve, reject) => {
        user(this.auth).subscribe(async (_user) => {

          if (_user){
            let token = await _user.getIdToken();

            let response = await this.authService.getDataUser(token);

            let ecmUser = response.data.user as EcmUser;

            // console.log(ecmUser);

            this.loaderService.loading = false;
            this.loaderService.message = '';

            if(ecmUser.profile.profile == 'admin'){
              resolve(true);
            } else {
              await this.authService.signOut();
              this.router.navigate(['/signin']);
              resolve(false);
            }
          } else {

            this.loaderService.loading = false;
            this.loaderService.message = '';
            this.router.navigate(['/signin']);
            resolve(false);
          }
        });
      });
  }
  
}
