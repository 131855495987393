export interface IStatus {
    status: string;
    description: string;
    position: number;
    createdAt: string;
    updatedAt: string;
}

export class Status {
    public status: string;
    public description: string;
    public position: number;
    public createdAt: Date;
    public updatedAt: Date;

    constructor(
        options: IStatus
    ) {
        this.status = options.status;
        this.description = options.description;
        this.position = options.position;
        this.createdAt = new Date(options.createdAt);
        this.updatedAt = new Date(options.updatedAt);
    }
}
