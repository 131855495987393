export interface IBrand {
    idBrand: number;
    name: string;
    idImage?: number;
    status: boolean;
    recordDate: string;
    lastUpdate: string;
}

export class Brand {

    public idBrand: number;
    public name: string;
    public idImage?: number;
    public status: boolean;
    public recordDate: string;
    public lastUpdate: string;

    constructor(
        options: IBrand,
    ) {
        this.idBrand = options.idBrand;
        this.name = options.name;
        this.idImage = options.idImage;
        this.status = options.status;
        this.recordDate = options.recordDate;
        this.lastUpdate = options.lastUpdate;
    }
}
