import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { Product } from 'src/app/models/product.model';

import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private toastService: ToastService,
  ) { 

  }

  /* -------------------------------------------------------------------------- */
  /*                               FUNCTION addTag                              */
  /* -------------------------------------------------------------------------- */

  async addTag(product: Product, tag: string):Promise<boolean> {
    
    let flag = false;
    
    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.post(
        `${environment.urlApi}/products/${product.idProduct}/tag`,
        JSON.stringify({
          tag: tag.trim()
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        console.log(response);
        
        resolve(response);
      });
    });

    if(response.statusCode == 201) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
    } else {
      this.toastService.show(response.message, {classname: `bg-danger text-white rounded-lg shadow`});
    }

    return flag;

  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCTION deleteTag                             */
  /* -------------------------------------------------------------------------- */

  async deleteTag(product: Product, tag: string):Promise<boolean> {
    
    let flag = false;
    
    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.delete(
        `${environment.urlApi}/products/${product.idProduct}/tag/${tag}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((response) => {
        resolve(response);
      });
    });

    if(response.statusCode == 200) {
      flag = true;
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
    } else {
      this.toastService.show(response.message, {classname: `bg-danger text-white rounded-lg shadow`})
    }

    return flag;

  }
}
