import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { IStatus, Status } from 'src/app/models/status.model';

import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service'
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  public statusArray: Array<Status> = [];
  public loading: boolean = true;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    public loaderService: LoaderService,
    private toastService: ToastService,
  ) {
    this.getStatus()
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗  ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝  ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION createStatus
   * 
   * @param status 
   * @param description 
   */
  async createStatus(status: string, description: string){
    this.loaderService.loading = true;
    this.loaderService.message = 'Agregando nuevo estatus';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    await new Promise((resolve, reject) => {
      this.http.post(
        `${environment.urlApi}/status`,
        JSON.stringify({
          status,
          description
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      .subscribe((response: any) => {
        console.log(response);
        
        this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
        this.loaderService.loading = false;
        this.loaderService.message = ''
        this.getStatus();
        resolve(true);
      });
    });
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION deleteStatus
   * 
   * @param status 
   */
  async deleteStatus(status: Status){

    this.loaderService.loading = true;
    this.loaderService.message = `Borrando estatus ${status.status}`;

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    await new Promise((resolve, reject) => {
      this.http.delete(
        `${environment.urlApi}/status/${status.status}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      .subscribe((response: any) => {
        console.log(response);
        
        this.toastService.show(response.message, {classname: `bg-success text-white rounded-lg shadow`});
        this.loaderService.loading = false;
        this.loaderService.message = ''
        this.getStatus();
        resolve(true);
      });
    });

  }

  /*
   ██████╗ ███████╗████████╗███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝
  ██║  ███╗█████╗     ██║   ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗
  ██║   ██║██╔══╝     ██║   ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║
  ╚██████╔╝███████╗   ██║   ███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║
   ╚═════╝ ╚══════╝   ╚═╝   ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION getStatus
   */
  async getStatus() {
    this.loading = true;
    let token = await this.authService.currentFirebaseUser?.getIdToken();

    await new Promise((resolve, reject) => {
      this.http.get(`${environment.urlApi}/status`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .subscribe((response: any) => {        
        this.statusArray = response.data.status
          .map((status: IStatus) => new Status(
            status
          ));
        this.loading = false;
      })
    })
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗  ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝  ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION updateStatus
   * 
   * @param oldStatus 
   * @param newStatus 
   */
  async updateStatus(oldStatus: Status, newStatus: IStatus) {

    this.loaderService.loading = true;
    this.loaderService.message = 'Agregando nuevo estatus';

    let token = await this.authService.currentFirebaseUser?.getIdToken();

    let response = await new Promise<any>((resolve, reject) => {
      this.http.put(
        `${environment.urlApi}/status/${oldStatus.status}`,
        JSON.stringify(newStatus),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).subscribe((data: any) => {
        console.log(data);

        resolve(data);
        
      });
    });

    if(response.statusCode == 200) {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-success text-white rounded-lg shadow`
        }
      );
      this.getStatus();
    } else {
      this.toastService.show(
        response.message, 
        {
          classname: `bg-danger text-white rounded-lg shadow`
        }
      );
    }

    this.loaderService.loading = false;
    this.loaderService.message = '';
  }
}
