import { Menu } from 'src/app/models/menu.model';

export const MENU: Array<Menu> = [
    {
        icon: 'dashboard',
        route: 'dashboard',
        title: 'Dashboard'
    },
    {
        icon: 'store',
        title: 'Tienda',
        children: [
            {
                title: 'Marcas',
                route: 'dashboard/store/brands'
            },
            {
                title: 'Categorias',
                route: 'dashboard/store/categories'
            },
            {
                title: 'Productos',
                route: 'dashboard/store/products'
            }
        ]
    },
    {
        icon: 'image',
        route: 'dashboard/media',
        title: 'Media'
    },
    {
        icon: 'security',
        title: 'Seguridad',
        children: [
            {
                icon: 'receipt_long',
                title: 'Bitácora',
                route: 'dashboard/security/logs'
            },
            {
                icon: 'group',
                title: 'Usuarios y permisos',
                route: 'dashboard/security/users'
            },
        ]
    },
    {
        icon: 'settings',
        title: 'Configuración',
        children: [
            {
                icon: 'checklist',
                title: 'Estatus',
                route: 'dashboard/settings/status'
            },
            {
                icon: 'folder',
                title: 'Variables',
                route: 'dashboard/settings/variables'
            },
        ]
    },
]