import { Component, OnInit, Input } from '@angular/core';
import { Menu } from 'src/app/models/menu.model';

import { SlideMenuService } from 'src/app/services/slide-menu/slide-menu.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() menuItem: Menu | undefined;

  public isCollapsed = true;

  constructor(
    public slideMenuService: SlideMenuService
  ) {
      // this.menuItem = new Menu();
   }

  ngOnInit(): void {
  }

}
