import { IStatus, Status } from "./status.model";

export interface IItem {
   idStock: string;
   idItem: string;
   createdAt: string;
   updatedAt: string;
   itemStatus: IStatus;
}

export class Item {
   public idStock: string;
   public idItem: string;
   public createdAt: Date;
   public updatedAt: Date;
   public itemStatus: Status;

   constructor(data: IItem) {
      this.idStock = data.idStock;
      this.idItem = data.idItem;
      this.createdAt = new Date(data.createdAt);
      this.updatedAt = new Date(data.updatedAt);
      this.itemStatus = new Status(data.itemStatus);
   }
}