import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BREADCRUMBS } from 'src/app/data/breadcrumbs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public paths: Array<string> = [];

  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router
  ) { 
    this.router.events.subscribe((data) => {
      this.paths = this.router.url.substring(1).split('/');
      // console.log(this.paths);
    })
  }

  ngOnInit(): void {
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION getName                              */
  /* -------------------------------------------------------------------------- */

  getName(path: string): string {
    let breadcrumbE = BREADCRUMBS.find(
      (breadcrumb) => breadcrumb.path == path
    );

    let pathName = '';

    if(breadcrumbE){
      pathName = breadcrumbE.name;
    } else {
      pathName = path;
    }

    return pathName;
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION getRoute                             */
  /* -------------------------------------------------------------------------- */

  getRoute(path: string): string {
    let pathLimiter = '';

    let flag = true;

    this.paths.forEach((element, index) => {
      
      if(flag) {
        pathLimiter += `/${element}`;
      }

      if(element == path) {
        flag = false;
      }
    });

    // console.log({
    //   path,
    //   pathLimiter
    // });
    

    return pathLimiter;
  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCTION getShow                              */
  /* -------------------------------------------------------------------------- */

  getShow(path: string): boolean {
    let breadcrumbE = BREADCRUMBS.find(
      (breadcrumb) => breadcrumb.path == path
    );

    let flag = true;

    if(breadcrumbE) {
      flag = breadcrumbE.show;
    }

    return flag;
  }

}
