import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { 
  HttpClientModule, 
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';

// ANCHOR NGPRIME
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';

import { IsAdminGuard } from 'src/app/guards/is-admin/is-admin.guard';
import { IsLoggedInGuard } from 'src/app/guards/is-logged-in/is-logged-in.guard';
import { IsNotLoggedInGuard } from 'src/app/guards/is-not-logged-in/is-not-logged-in.guard';

import { AuthService } from 'src/app/services/auth/auth.service';
import { BrandService } from 'src/app/services/brand/brand.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductService } from 'src/app/services/product/product.service';
import { SlideMenuService } from 'src/app/services/slide-menu/slide-menu.service';
import { StatusService } from './services/status/status.service';
import { StockService } from './services/stock/stock.service';
import { ToastService } from 'src/app/services/toast/toast.service';

import { HttpErrorInterceptor } from 'src/app/interceptors/error.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { CategoryLayoutComponent } from './layouts/category-layout/category-layout.component';
import { ToastsContainer } from './components/toast-container/toat-container';

import { HeaderDashboardComponent } from 'src/app/components/header-dashboard/header-dashboard.component';
import { SideBarComponent } from 'src/app/components/side-bar/side-bar.component';
import { FooterDashboardComponent } from './components/footer-dashboard/footer-dashboard.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { SETTINGS as AUTH_SETTINGS, LANGUAGE_CODE, PERSISTENCE } from '@angular/fire/compat/auth';
import { LoaderComponent } from './components/loader/loader.component';
import { SlideMenuComponent } from './components/slide-menu/slide-menu.component';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [
    AppComponent,
    BreadcrumbComponent,
    SessionLayoutComponent,
    DashboardLayoutComponent,
    HeaderDashboardComponent,
    SideBarComponent,
    FooterDashboardComponent,
    MenuItemComponent,
    LoaderComponent,
    ToastsContainer,
    SlideMenuComponent,
    CategoryLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    InputSwitchModule,
    TableModule,
  ],
  providers: [
    AuthService,
    BrandService,
    IsAdminGuard,
    IsLoggedInGuard,
    IsNotLoggedInGuard,
    LoaderService,
    ProductService,
    SlideMenuService,
    StockService,
    ToastService,
    StatusService,
    { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } },
    { provide: LANGUAGE_CODE, useValue: 'es' },
    { provide: PERSISTENCE, useValue: 'session' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
