export interface IImage {
   idImage: string;
   urlImage: string;
   path: string;
   service: string;
   recordDate: string;
}

export class Image {
   public idImage: string;
   public urlImage: string;
   public path: string;
   public service: string;
   public recordDate: Date;
   
   constructor(data: IImage) {
      this.idImage = data.idImage;
      this.urlImage = data.urlImage;
      this.path = data.path;
      this.service = data.service;
      this.recordDate = new Date(data.recordDate);
   }
}