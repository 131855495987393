export interface ITag {
    tag: string;
}

export class Tag{
    tag: string;

    constructor(
        options: ITag
    ) {
        this.tag = options.tag;
    }
}
