<div class="col-12 mb-3">
    <a 
        (click)="slideMenuService.showed = false"
        routerLink="/{{menuItem!.route}}"
        *ngIf="!menuItem!.children"
        class="btn d-flex align-items-center justify-content-between text-white">
        <span class="d-flex align-items-center">
            <i 
                *ngIf="menuItem!.icon"
                class="material-icons me-2">
                {{menuItem!.icon}}
            </i>
            {{menuItem!.title}}
        </span>
        <!-- <i class="material-icons">chevron_right</i> -->
    </a>

    <button
        *ngIf="menuItem!.children"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        class="btn d-flex align-items-center justify-content-between text-white w-100">
        <span class="d-flex align-items-center">
            <i 
                *ngIf="menuItem!.icon"
                class="material-icons me-2">
                {{menuItem!.icon}}
            </i>
            {{menuItem!.title}}
        </span>
        <i class="material-icons">
            {{isCollapsed ? 'chevron_right' : 'keyboard_arrow_down'}}
        </i>
    </button> 

    <div
        *ngIf="menuItem!.children"
        #collapse="ngbCollapse" 
        [(ngbCollapse)]="isCollapsed">
        
        <div class="col-12 ps-4 pt-3">
            <app-menu-item 
                *ngFor="let menu of menuItem?.children"
                [menuItem]="menu">
            </app-menu-item>
        </div>
    </div>
</div>