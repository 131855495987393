export interface IFeature {
    idFeature: string;
    idProduct: string;
    feature: string;
    value: string;
    erasable: boolean;
}

export class Feature {
    public idFeature: string;
    public idProduct: string;
    public feature: string;
    public value: string;
    public erasable: boolean;

    constructor(
        options: IFeature
    ) {
        this.idFeature = options.idFeature;
        this.idProduct = options.idProduct;
        this.feature = options.feature;
        this.value = options.value;
        this.erasable = options.erasable;
    }
}